<template>
  <div class="container">
    <div class="login-form">
      <div class="logo-row">
        <img style="height: 30px; width: 120px; left:0;" src="@/assets/image/qucoon-logo.png" />
        <img class="fbn-logo" style="height: 30px; width: 125px; right:0;" :src="task.data.projectCompanyLogo" />
      </div>
      <p class="p-header">{{ task.data.taskProjectName }}</p>
      <p class="p-title">Task Ref:</p>
      <p class="p-desc">{{ task.data.taskRef }}</p>
      <p class="p-title">Project Task:</p>
      <p class="p-desc">{{ task.data.taskItem }}</p>
      <p class="p-title">Target Date:</p>
      <p class="p-desc">{{ task.data.taskEndDate }}</p>
      <label class="p-title">Status:</label>
      <br>
      <select v-model="updateModel.taskStatus" class="">
        <option value="NOT_STARTED">NOT STARTED</option>
        <option value="COMPLETED">COMPLETED</option>
        <option value="ONGOING">ONGOING</option>
        <option value="TASK_IS_AT_RISK">TASK IS AT RISK</option>
      </select>
      <br>
      <br>
      <label class="p-title">Comment:</label>
      <br>
      <textarea v-model="updateModel.taskComment"></textarea>
      <br>
      <br>
      <button @click="modifyTask" v-if="!loading" >Submit</button>
      <button  v-else disabled>
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        Loading...
      </button>
    </div>
  </div>
</template>

<script>
import TaskRequest from "../../model/request/TaskRequest";
import { mapState } from "vuex";

export default {
  name: "TaskUpdateForm",
  props: ["disabled"],
  data(){
    return{
      model: TaskRequest.taskReadSingleRequest,
      updateModel: TaskRequest.taskUpdateRequest

    }
  },

  computed:{
    // ...mapState(["task"]),
    ...mapState({
      task: (state) => state.task.task,
      loading: (state) => state.task.editLoading,
    })
    // taskId(){
    //   return this.$route.query.taskid
    // },
  },
  methods:{
    modifyTask(){
      this.updateModel.taskCategory = this.task.data.taskCategory
      this.updateModel.taskId = this.task.data.taskId
      this.updateModel.taskCreatedAt = this.task.data.taskCreatedAt
      this.updateModel.taskDays = this.task.data.taskDays
      this.updateModel.taskCompanyRef = this.task.data.taskCompanyRef
      this.updateModel.taskDependency = this.task.data.taskDependency
      this.updateModel.taskItem = this.task.data.taskItem
      this.updateModel.taskProjectName = this.task.data.taskProjectName
      this.updateModel.taskRef = this.task.data.taskRef
      this.updateModel.taskResponsibility = this.task.data.taskResponsibility
      this.updateModel.taskSprintNumber = this.task.data.taskSprintNumber
      this.updateModel.taskStartDate = this.task.data.taskStartDate
      this.updateModel.taskEndDate = this.task.data.taskEndDate
      this.updateModel.taskTcNumber = this.task.data.taskTcNumber
      this.updateModel.taskTiNumber = this.task.data.taskTiNumber
      this.$store.dispatch("task/editTask", this.updateModel)
      console.log(this.updateModel)

    }
  },
  mounted() {
    this.model.taskId = this.$route.query.taskid,
    console.log(this.model.taskId)
    this.$store.dispatch("task/updateTask", this.model)

  }
};
</script>

<style scoped>

.container {
  background-color: #ffffff;
  height: 100%;
  width: 30%;
  margin: 10% auto auto;
  padding: 25px;
  border-radius: 20px;
}
.logo-row {
  /*position: absolute;*/
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.login-form{

}
.p-header{
  font-family: Roobert;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #003B65;
  text-align: center;
}
.p-title{
  font-family: Roobert;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #5A6C76;
}
.p-desc{
  font-family: Roobert;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 12px;
  color: #7D90B8;
}
input, select{
  font-family: Roobert;
  box-sizing: border-box;
  width: 100%;;
  height: 40px;
  border: 1px solid #9CABC9;
  border-radius: 5px;
  padding: 10px;
}
textarea{
  font-family: Roobert;
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  border: 1px solid #9CABC9;
  border-radius: 10px;
  padding: 10px;
}
button{
  width: 100%;
  height: 40px;
  background: #003B65;
  border-radius: 5px;
  color: #fff;
  border: none;
}

@media screen and (max-width: 800px) {
  .container {
    width: 80%;
  }
}

@media screen and (max-width: 360px) {
  .logo-row {
    flex-direction: column;
    align-items: center;
  }
  .fbn-logo {
    margin-top: 10px;
  }
}

/*@media screen and (max-width: 300px) {*/
/*  .container{*/
/*    margin-top: 30px;*/
/*  }*/
/*}*/
</style>
