<template>
  <div>
    <base-loader v-if="loading" />
    <task-update-form />
  </div>
</template>

<script>
// @ is an alias to /src
import TaskUpdateForm from "@/components/form/TaskUpdateForm";
import BaseLoader from "@/components/loader/BaseLoader";
import { mapState } from "vuex";

export default {
  name: "Home",
  components: {
    BaseLoader,
    TaskUpdateForm,
  },
  computed:{
    ...mapState({
      loading: (state) => state.task.loading,
    })
  }
};
</script>
