import axios from "axios";
import store from "@/store/store";
import { baseUrl } from "../../config/config.config.json";

const apiClient = axios.create({
    baseURL: baseUrl,
    withCredentials: false,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
    },
});

    apiClient.interceptors.request.use((config) => {
        // console.log("Axios Interceptor Config ==>", config);
        const token = store.getters.getToken;
        const tok = store.getters["auth/getTok"];
        // console.log("INTERCEPTOR TOKEN", token)
        // console.log("INTERCEPTOR  TOK ==>", tok)
        config.headers.Authorization = token == null ? tok : token;
        return config;
    });


// const s3ApiClient = axios.create({
//     baseURL: window.__env.api.uploadS3BaseUrl,
//     withCredentials: false,
//     headers: {
//         Accept: "application/json",
//         "Content-Type": "application/json",
//     },
// });

// s3ApiClient.interceptors.request.use((config) => {
//     // console.log("Axios Interceptor Config ==>", config);
//     const token = store.getters.getToken;
//     const tok = store.getters["auth/getTok"];
//     // console.log("INTERCEPTOR TOKEN", token)
//     // console.log("INTERCEPTOR  TOK ==>", tok)
//     config.headers.Authorization = token == null ? tok : token;
//     return config;
// });

export default {
  apiClient: apiClient,
};