class TaskRequest {
  static taskCreateRequest = {
    taskRef: null,
    taskProjectName: null,
    taskTcNumber: null,
    taskCategory: null,
    taskTiNumber: null,
    taskItem: null,
    taskDependency: null,
    taskDays: null,
    taskStatus: null,
    taskResponsibility: null

  }
  taskReadRequest() {
    return {
      readAll: "YES",
    };
  }
  static taskReadSingleRequest = {
    taskId :  null
  }
  static taskUpdateRequest = {

  taskId: null,
  taskRef: null,
  taskProjectName: null,
  taskTcNumber: null,
  taskCategory: null,
  taskTiNumber: null,
  taskItem: null,
  taskDependency: null,
  taskStartDate: null,
  taskEndDate: null,
  taskDays: null,
  taskStatus: null,
  taskResponsibility: null,
  taskCreatedAt: null,
  taskCompanyRef: null,
  taskComment: null,
  taskSprintNumber: null

  }
}

export default TaskRequest;
