import swal from "sweetalert";
import store from "../store";
import TaskService from "../../service/TaskService";
import TaskResponse from "../../model/response/TaskResponse";
import TaskRequest from "../../model/request/TaskRequest";

export const state = {
  task: TaskResponse.prototype.taskReadSingleResponse().data,
  tasks: TaskResponse.prototype.taskReadResponse(),
  screen: "one",
  model: TaskRequest.prototype.taskReadRequest(),
  loading: false,
  editLoading: false,
};
export const getters = {};
export const mutations = {
  updateTask: (state, payload) => {
    state.task = payload;
  },
  updateTasks: (state, payload) => {
    state.tasks = payload;
  },
  updateModel: (state, payload) => {
    state.model = payload;
  },
  updateTaskLoading: (state, payload) => {
    state.loading = payload;
  },
  updateEditLoading: (state, payload) => {
    state.editLoading = payload;
  },
};
export const actions = {

  updateTasks: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateTaskLoading", true);
    return TaskService.callReadTaskApi(payload)
      .then((response) => {
        commit("updateTaskLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateTasks", responseData);
        }
      })
      .catch((err) => {
        commit("updateTaskLoading", false);
        console.log(err);
      });
  },
  updateTask: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateTaskLoading", true);
    return TaskService.callReadSingleTaskApi(payload)
      .then((response) => {
        commit("updateTaskLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          commit("updateTask", responseData);
        }else {
          swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateTaskLoading", false);
        console.log(err);
      });
  },
  createTask: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateTaskLoading", true);
    return TaskService.callCreateTaskApi(payload)
      .then((response) => {
        commit("updateTaskLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          store.commit("updateMessage", {
            type: "success",
            title: "Success",
            body: responseData.responseMessage,
          });
        } else {
          store.commit("updateMessage", {
            type: "error",
            title: "Error",
            body: responseData.responseMessage,
          });
        }
      })
      .catch((err) => {
        commit("updateTaskLoading", false);
        console.log(err);
      });
  },
  editTask: ({ commit }, payload) => {
    //TODO USER LOADING
    commit("updateEditLoading", true);
    return TaskService.callUpdateTaskApi(payload)
      .then((response) => {
        commit("updateEditLoading", false);
        let responseData = response.data;
        if (responseData.responseCode === "00") {
          swal("Success", responseData.responseMessage, "success");
        } else {
          swal("Error", responseData.responseMessage, "error");
        }
      })
      .catch((err) => {
        commit("updateEditLoading", false);
        console.log(err);
      });
  },
};
