class TaskResponse {
  taskCreateResponse() {
    return {
      responseCode: null,
      responseMessage: null,
    };
  }
  taskReadResponse() {
    return {
      responseCode: null,
      responseMessage: null,
      data: [
        {
          taskId: null,
          taskRef: null,
          taskProjectName: null,
          taskTcNumber: null,
          taskCategory: null,
          taskTiNumber: null,
          taskItem: null,
          taskDependency: null,
          taskStartDate: null,
          taskEndDate: null,
          taskDays: null,
          taskStatus: null,
          taskResponsibility: null,
          taskCreatedAt: null,
          taskCompanyRef: null,
          taskSprintNumber: null
        },
      ],
    };
  }
  taskReadSingleResponse() {
    return {
      responseCode: null,
      responseMessage: null,
      data: [
        {
          taskId: null,
          taskRef: null,
          taskProjectName: null,
          taskTcNumber: null,
          taskCategory: null,
          projectCompanyLogo: null,
          taskTiNumber: null,
          taskItem: null,
          taskDependency: null,
          taskStartDate: null,
          taskEndDate: null,
          taskDays: null,
          taskStatus: null,
          taskResponsibility: null,
          taskCreatedAt: null,
          taskCompanyRef: null,
          taskSprintNumber: null
        },
      ],
    };
  }
  taskUpdateResponse() {
    return {
      responseCode: null,
      responseMessage: null,
    };
  }
}

export default TaskResponse;
