import client from "./BaseService";

export default {
  callCreateTaskApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return client.apiClient.post("task/create", payload);
  },
  callReadTaskApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return client.apiClient.post("task/read", payload);
  },
  callReadSingleTaskApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return client.apiClient.post("task/read-by-task-id", payload);
  },
  callUpdateTaskApi(payload) {
    // console.log("Service Payload ==>>", payload)
    return client.apiClient.post("task/update", payload);
  },
};